import React from 'react'

import { parseHTML } from './../../helpers/parseHTML';
import Img from 'gatsby-image/withIEPolyfill';
// @ts-ignore
import BrYellow from '../../svgs/br-yellow.svg'
// @ts-ignore
import HomepageCblockLeftSVG from '../../svgs/homepage-cblock-left.svg'
// @ts-ignore
import HomepageCblockRightSVG from '../../svgs/homepage-cblock-right.svg'
// @ts-ignore
import HomepageCblockLeftAnimatedSVG from '../../svgs/homepage-cblock-left-animated.svg'
// @ts-ignore
import HomepageCblockRightAnimatedSVG from '../../svgs/homepage-cblock-right-animated.svg'

interface props {
  data: any
  browser: string
}

interface state {
  revealSides: boolean
}

export default class HomepageCblock extends React.Component<props, state> {
  cblock: HTMLElement;
  hasRevealed: boolean;
  scrollEventListener: Function;

  constructor(props) {
    super(props);
    this.state = {
      revealSides: false
    };

    this.hasRevealed = false;
  }

  componentDidMount = () => {
    this.scrollEvent();
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('scroll', this.scrollEventListener);
  }

  scrollEvent = () => {
    let last_known_scroll_position = 0;
    let ticking = false;

    // Scroll event with throttling
    window.addEventListener('scroll', this.scrollEventListener = (e) => {
      if (!this.hasRevealed) {
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            this.scrollReveal(last_known_scroll_position);
            ticking = false;
          });

          ticking = true;
        }
      }
    });    
  }

  scrollReveal = (scroll_pos: number): void => {
    // When scroll goes to cblock + half of page, enable svg reveal
    const cblockOffset = this.cblock.offsetTop;

    if ((cblockOffset < scroll_pos - window.innerHeight) && !this.hasRevealed) {
      this.hasRevealed = true;
      this.setState({revealSides: true})
    }
  }

  render () {
    let data = this.props.data;

    return (
      <div className="homepage-cblock__outer"> 
        {this.props.browser == 'ie' || this.props.browser == 'edge' ?
          <>
            <HomepageCblockLeftSVG className="homepage-cblock__svg left"/>
            <HomepageCblockRightSVG className="homepage-cblock__svg right"/>
          </>
        :
          <>
            {this.state.revealSides && 
              <>
                <HomepageCblockLeftAnimatedSVG className="homepage-cblock__svg left"/>
                <HomepageCblockRightAnimatedSVG className="homepage-cblock__svg right"/>
              </>
            }
          </>
        }
        <div 
          className="outer-container homepage-cblock"
          ref={cblock => this.cblock = cblock}
        >
          <div className="middle-container">

            <div className="homepage-cblock__left">
              <div className="homepage-cblock__image">
                <Img fluid={data.homepageCblock.cblockImage.fluid} alt={data.homepageCblock.cblockImage.alt} />
              </div>
            </div>
            <div className="homepage-cblock__right">
              <h2 className="homepage-cblock__title">{data.homepageCblock.cblockTitle}</h2>
              <BrYellow/>
              <div className="homepage-cblock__text">{parseHTML(data.homepageCblock.cblockText)}</div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
