import React from 'react';
import AnimatedLink from './../global/animatedLink';
// @ts-ignore
import ArrowRightGrey from '../../svgs/arrow-right-grey.svg';
// @ts-ignore
import BrYellow from '../../svgs/br-yellow.svg';

interface props {
  data: any;
}

interface state {}

export default class HomepageCTA extends React.Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let data = this.props.data;

    return (
      <>
        <div className='middle-container homepage-cta'>
          <div className='homepage-cta__left'>
            {data.homepageCTA.ctas.map((cta, index) => {
              return (
                <AnimatedLink
                  to={`/learning-areas/`}
                  className='homepage-cta__cta'
                  key={`cta-` + index}
                  state={{ state: { subject: cta.ctaTopic } }}
                >
                  {cta.ctaTopic === 'Mathematics' ? (
                    <p className='homepage-cta__cta-heading-black'>
                      {cta.ctaTopic}
                    </p>
                  ) : (
                    <p className='homepage-cta__cta-heading'>{cta.ctaTopic}</p>
                  )}
                  <img
                    className='homepage-cta__cta-image'
                    src={cta.ctaImage.url}
                    alt={cta.ctaImage.alt}
                  />
                  <div className='homepage-cta__cta-footer'>
                    <span className='homepage-cta__footer-title'>
                      Discover {cta.ctaTopic}
                    </span>
                    <ArrowRightGrey />
                  </div>
                </AnimatedLink>
              );
            })}
          </div>
          <div className='homepage-cta__right'>
            <h2 className='homepage-cta__title'>{data.homepageCTA.ctaTitle}</h2>
            <BrYellow />
            <p className='homepage-cta__text'>
              {data.homepageCTA.ctaDescription}
            </p>
          </div>
        </div>
      </>
    );
  }
}
