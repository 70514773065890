import React from 'react'
import Layout from '../components/layouts/layout'
import { graphql } from 'gatsby'
import SEO from '../components/layouts/seo';
import HomepageHero from './../components/homepage/homepage-hero';
import HomepageCTA from '../components/homepage/homepage-cta';
import HomepageFeature from './../components/homepage/homepage-feature';
import HomepageCblock from './../components/homepage/homepage-cblock';
import HomepageAccordions from './../components/homepage/homepage-accordions';
import { connect } from 'react-redux';
import { setSearchTerm, setSearchTermActive } from './../state/actions';

interface props {
  data: any
  browser: string
  transitionStatus: string
  setSearchTerm: Function
  setSearchTermActive: Function
}

interface state {
}

class Homepage extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let browser = this.props.browser;
    let {transitionStatus} = this.props;

    return (
      <>
        <SEO
          title={'Home'} 
          description={'homepage'} 
        />
        <Layout 
          data={data}
          class={`homepage`}
          transitionStatus={transitionStatus}
        >
          <HomepageHero 
            data={data} 
            browser={browser} 
            setSearchTerm={this.props.setSearchTerm}
            setSearchTermActive={this.props.setSearchTermActive}
          />
          <HomepageCTA data={data}/>
          {/* <HomepageFeature data={data}/> */}
          <HomepageCblock data={data} browser={browser}/>
          {/* <HomepageAccordions data={data}/> */}
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    homepageHero: datoCmsHomepageHero {
      heroText
      heroTitle
      heroSearchPlaceholder
      heroSideImage {
        url
      }
    }
    homepageCTA: datoCmsHomepageCtaSection {
      ctaTitle
      ctaDescription
      ctas {
        ctaImage {
          alt
          url
        }
        ctaTopic
      }
    }
    homepageFeature: datoCmsHomepageFeature {
      featureTitle
      feature {
        featureText
        featureTitle
        featureImage {
          alt
          url
        }
      }
    }
    homepageCblock: datoCmsHomepageCblock {
      cblockText
      cblockTitle
      cblockImage {
        alt
        url
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    homepageAccordion: datoCmsHomepageAccordion {
      accordionTitle
      accordions {
        accordionText
        accordionTitle
      }
    }
  }
`

export default connect(
  state => ({ 
    browser: state.browser,
  }),
  dispatch => ({ 
    setSearchTerm: term => dispatch(setSearchTerm(term)),
    setSearchTermActive: active => dispatch(setSearchTermActive(active)),
  }),
)(Homepage);