import React from 'react'
import { navigate } from 'gatsby'
import animateScrollTo from 'animated-scroll-to';
// @ts-ignore
import HomepageTopLeftSVG from '../../svgs/homepage-top-left.svg'
// @ts-ignore
import HomepageTopRightSVG from '../../svgs/homepage-top-right.svg'
// @ts-ignore
import HomepageTopLeftAnimatedSVG from '../../svgs/homepage-top-left-animated.svg'
// @ts-ignore
import HomepageTopRightAnimatedSVG from '../../svgs/homepage-top-right-animated.svg'
// @ts-ignore
import ArrowWhiteRight from '../../svgs/arrow-white-right.svg'
// @ts-ignore
import ArrowPinkDown from '../../svgs/arrow-pink-down.svg'
// @ts-ignore
import SearchMagnify from '../../svgs/search-magnify.svg'

interface props {
  data: any,
  browser: string
  setSearchTerm: Function
  setSearchTermActive: Function
}

interface state {
  placeholder: string
}

export default class HomepageHero extends React.Component<props, state> {
  searchInput: HTMLInputElement;
  resizeEventListener: Function;
  
  constructor(props) {
    super(props);
    this.state = {
      placeholder: this.props.data.homepageHero.heroSearchPlaceholder
    };

  }

  componentDidMount = () => {
    this.windowResizeEvent();
  }

  windowResizeEvent = () => {
    this.windowResizePlaceholder();
    let ticking = false;

    // Resize event with throttling
    window.addEventListener('resize', this.resizeEventListener = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.windowResizePlaceholder();
          ticking = false;
        });
        ticking = true;
      }
    }); 
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('resize', this.resizeEventListener);
  }

  windowResizePlaceholder = () => {
    if (window.innerWidth < 768) {
      this.setState({
        placeholder: 'Search'
      })
    } else {
      this.setState({
        placeholder: this.props.data.homepageHero.heroSearchPlaceholder
      })
    }
  }

  scrollDown = () => {
    let ctaElement : HTMLElement = document.querySelector('.homepage-cta');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    animateScrollTo(ctaElement.getBoundingClientRect().top + scrollTop - 10, {
      speed: 2000
    })
  }

  render () {
    let data = this.props.data;

    return (
      <>
        <div className="outer-container">
          {this.props.browser == 'ie' || this.props.browser == 'edge' ?
            <>
              <HomepageTopLeftSVG className="homepage__top-left-svg"/>
              <HomepageTopRightSVG className="homepage__top-right-svg"/>
            </>
          :
            <>
              <HomepageTopLeftAnimatedSVG className="homepage__top-left-svg"/>
              <HomepageTopRightAnimatedSVG className="homepage__top-right-svg"/>
            </>
          }
          <img src={data.homepageHero.heroSideImage.url} className="homepage__hero-side-image"/>
          <div className="homepage__hero hero-container">
            <h1 className="homepage__hero-title">{data.homepageHero.heroTitle}</h1>
            <p className="homepage__hero-text">{data.homepageHero.heroText}</p>
            <form 
              className="homepage__search"     
              onSubmit={event => {
                event.preventDefault();
                this.props.setSearchTermActive(false);
                this.props.setSearchTerm(this.searchInput.value);
                navigate("/learning-areas/", {state: { search: this.searchInput.value }})
              }}
            >
              <SearchMagnify className="homepage__search-icon" />
              <input 
                type="text" 
                className="homepage__search-input"
                placeholder={this.state.placeholder}
                ref={searchInput => this.searchInput = searchInput}
                aria-label="Search"
              />
              <button className="homepage__search-submit">
                <span>
                  Search <ArrowWhiteRight/>
                </span>
              </button>
            </form>
            <button 
              className="homepage__hero-scroll-down"
              onClick={this.scrollDown}
            >
              <ArrowPinkDown />
              <p>Scroll down</p>
            </button>
          </div>
        </div>
      </>
    )
  }
}